import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { oneOfType, arrayOf, node } from 'prop-types';
import theme from 'theme';

import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { isAuthenticated } from 'services/authentication-service';
import { useInitLoadContext } from 'contexts/init-load-context';
import { useAuth0 } from '@auth0/auth0-react';

import useStyles from './styles';

const Backdrop = loadable(() => import('@material-ui/core/Backdrop'));
const CssBaseline = loadable(() => import('@material-ui/core/CssBaseline'));

const BrowserWrapper = ({ children }) => {
  const rootStyles = useStyles();
  const [loading, setLoading] = useState(false);

  const { isLoading } = useAuth0();
  const { dataLoading } = useInitLoadContext();

  useEffect(() => {
    if (isAuthenticated()) {
      setLoading(dataLoading);
    } else {
      setLoading(isLoading);
    }

  // eslint-disable-next-line
}, [dataLoading, isLoading])

  return (
      <div className={rootStyles.root}>
       <ThemeProvider theme={theme}>
         <CssBaseline />
          {children}
          <Backdrop className={rootStyles.backdrop} open={loading}/>
      </ThemeProvider>
    </div>
  );
};

BrowserWrapper.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default BrowserWrapper;
